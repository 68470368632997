@import "../node_modules/angular-calendar/css/angular-calendar.css";

.bg-custom {
  background-color: #021833 !important;
}

.bg-custom2 {
  background-color: #fdb500 !important;
}

.card .card-title {
  text-transform: initial !important;
}

.p-component {
  font-family: "Montserrat", Helvetica, Arial, serif !important;
}


.p-button {
  background: #0068ff;
  border-radius: 50px;
  border: 1px solid #0068ff;
}

.p-button:enabled:hover {
  background: #0540e3;
  color: #ffffff;
  border-color: #0540e3;
}

.btn-danger:hover, .btn-danger.hover {
  background-color: #ff5b5c !important;
  color: #fff;
  border-color: #ff5b5c !important;
  box-shadow: -5px 5px 0 #ec6f6f70 !important;
}

.br-0 {
  border-radius: inherit !important;
}

.side-bar-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
}

.side-bar-card-header {
  border-bottom: 5px solid #fdb500 !important;
}


.card-header-b-yellow {
  border-bottom: 5px solid #fdb500 !important;
}

.bx-1 {
    font-size: 1rem !important;
}

.custom-side-bar {
  .p-sidebar-content {
    .p-sidebar-close {
      color: #fff !important;
      background: #fdb500 !important;
      top: 15px !important;
      right: 10px !important;
    }
  }
}


.custom-picklist {
  .p-picklist-list {
    height: 300px;
    overflow: auto;
  }
}


.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #FFFFFF;
  background: rgb(4, 100, 251) !important;
  box-shadow: -3px 3px 0 #ffc000 !important;
  margin-bottom: 8px;
}

.foo {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 15px;
  display: contents;
}
.card .card-title {
  font-size: 1.1rem;
  font-weight: 600;
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
      stroke: #d62d20;
  }
  40% {
      stroke: #0057e7;
  }
  66% {
      stroke: #008744;
  }
  80%,
  90% {
      stroke: #ffa700;
  }
}



.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #0068ff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}


.p-datatable .p-datatable-thead > tr > th {
  padding: .5rem .5rem;
  font-size: 13px;
}

.p-datatable .p-datatable-header {
  background: #ffffff;
  border: 1px solid #ffffff;
}

.p-datatable .p-datatable-thead > tr > th {
  border: 1px solid #f6f6f6;
}

.p-datatable .p-paginator-bottom {
  border-width: 0 0 0 0;
  border-radius: 0;
  background-color: transparent
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #0068ff;
  border-color: #0068ff;
  color: #ffffff;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
  font-size: 14px;
}

.rl-btn {
  margin-bottom: 3px;
}

.p-chips {
  display: grid !important;
}

.p-component-overlay {
  background-color: rgba(4, 100, 251, 0.49);
}
.p-dropdown-custom {
  display: grid !important;
}

.p-password {
    display: grid !important;
}


.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding: 0px;
}

.p-sidebar .p-sidebar-header {
  display: none;
}

.custom-badge {
  font-size: 0.8rem;
  color: #0ea60e;
  background-color: #00801a57;
  padding: 2px;
  border-radius: 26px;
}

.bg-violet {
  background-color: #6900b2 !important;
}

.text-violet {
  color: #6900b2 !important;
}

.bg-darker {
  background-color: #001a62 !important;
}

.text-darker {
  color: #001a62 !important;
}
