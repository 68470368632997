.btn {
  border-radius: 50px;
}

.btn-primary:focus, .btn-primary:hover, .btn-primary:visited {
  transform: translate(1px,-1px) im !important;
  box-shadow: -5px 5px 0 #ffc000 !important;
}

.main-menu.menu-dark .navigation > li.active:not(.sidebar-group-active) > a {
  background: rgb(4, 100, 251) !important;
  color: #fff !important;
  box-shadow: -3px 3px 0 #ffc000 !important;
}

body.semi-dark-layout .main-menu-content .navigation-main .active .menu-title,
body.semi-dark-layout .main-menu-content .navigation-main .active i {
  color: white !important;
}

body.semi-dark-layout .main-menu-content .navigation-main .nav-item .menu-content .active .menu-item {
  color: white !important;
}

body.semi-dark-layout .main-menu-content .navigation-main .nav-item .menu-content .active a {
  background-color: #0068ff !important;
  border-radius: 0.267rem;
  box-shadow: -3px 3px 0 #ffc000 !important;
}

.main-menu.menu-dark .navigation > li.nav-item.open.has-sub.open, .main-menu.menu-dark .navigation > li.nav-item.open.has-sub.sidebar-group-active, .main-menu.menu-dark .navigation > li.nav-item.sidebar-group-active.has-sub.open, .main-menu.menu-dark .navigation > li.nav-item.sidebar-group-active.has-sub.sidebar-group-active {
  border: 1px solid #0068ff
}

